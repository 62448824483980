import React, { FC, createContext, useReducer, Dispatch, useCallback } from 'react'
import Confirm from './confirm'
import { reducer, State, Action } from './state'

const CONTEXT = createContext<[State, Dispatch<Action>]>([[], () => null])

export const ModalProvider: FC = ({ children }) => {
  const [modals, dispatch] = useReducer(reducer, [])
  const renderModal = useCallback(
    ([modalProps]: State) => (Boolean(modalProps) ? <Confirm {...modalProps} /> : null),
    [],
  )

  return (
    <CONTEXT.Provider value={[modals, dispatch]}>
      {children}
      {renderModal(modals)}
    </CONTEXT.Provider>
  )
}

export default CONTEXT
