import { Reducer } from 'react'
import { Props as ConfirmProps } from './confirm'

export const queueConfirm = (payload: ConfirmProps & { id: symbol }) =>
  ({
    type: 'confirm',
    payload,
  } as const)

export const resolveConfirm = (id: symbol) =>
  ({ type: 'resolveConfirm', payload: id } as const)
export const rejectConfirm = (id: symbol) =>
  ({ type: 'rejectConfirm', payload: id } as const)

export type Action = ReturnType<
  typeof queueConfirm | typeof resolveConfirm | typeof rejectConfirm
>

export type State = (ConfirmProps & { id: symbol })[] // TODO: use multiple prop types

export const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'confirm':
      return [...state, action.payload]
    case 'resolveConfirm':
    case 'rejectConfirm':
      return state.filter(({ id }) => id !== action.payload)
    default:
      return state
  }
}
