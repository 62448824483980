import { Box, Button, Layer, Text } from 'grommet'

import React, { FC } from 'react'

export type Props = {
  message: string
  onOK(): void
  onCancel(): void

  okText?: string
  cancelText?: string
}

const Confirm: FC<Props> = ({
  cancelText = 'Cancel',
  message,
  okText = 'OK',
  onCancel,
  onOK,
}) => {
  return (
    <Layer position="center" modal margin="none" responsive>
      <Box direction="column" width="500px">
        <Box height="small" align="center" justify="center">
          <Text textAlign="center">{message}</Text>
        </Box>
        <Box direction="row" alignContent="end" justify="end" margin="medium">
          <Button plain color="dark-1" onClick={onCancel}>
            {cancelText}
          </Button>
          <Button plain color="dark-1" onClick={onOK} margin="small">
            {okText}
          </Button>
        </Box>
      </Box>
    </Layer>
  )
}

export default Confirm
